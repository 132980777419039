import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Select from 'react-select';
import Loading from 'components/Loading';
import {
    getShows,
    getFilterOptions,
    selectors,
    setFilter,
} from '../../ducks/shows';
import { gettext } from '../../utils/text';
import SingleShow from './SingleShow';
import { OptionShape, ShowShape } from './types';
import DropdownIndicator from '../DropdownIndicator';

class HomepageWrapper extends React.Component {
    static propTypes = {
        shows: PropTypes.arrayOf(ShowShape).isRequired,
        filters: PropTypes.instanceOf(Object).isRequired,
        stagings: PropTypes.arrayOf(OptionShape).isRequired,
        periods: PropTypes.arrayOf(OptionShape).isRequired,
        locations: PropTypes.arrayOf(OptionShape).isRequired,
        getFilterValue: PropTypes.func.isRequired,
        getShows: PropTypes.func.isRequired,
        getFilterOptions: PropTypes.func.isRequired,
        applyFilter: PropTypes.func.isRequired,
        initialLoading: PropTypes.bool.isRequired,
        nextLoading: PropTypes.bool.isRequired,
        nextPage: PropTypes.string,
    };

    static defaultProps = {
        nextPage: null,
    };

    constructor(props) {
        super(props);
        const query = queryString.parse(window.location.search, {
            parseNumbers: true,
        });
        this.state = { setInitialHall: query.hall, pageNumber: 1 };
    }

    componentDidMount() {
        this.props.getShows(this.props.filters);
        this.props.getFilterOptions(this.props.filters);
    }

    componentDidUpdate() {
        this.setInitialHallIfNeeded();
    }

    onFilterChange = (filter, option) => {
        this.props.applyFilter(filter, option);
        const newFilter = { ...this.props.filters, upcomingShowsPageNumber: 1 };
        newFilter[filter] = option;
        // on filter change load shows from beginning
        this.props.getShows(newFilter, true);
        this.props.getFilterOptions(newFilter);
    };

    setInitialHallIfNeeded() {
        // if halls options are not empty and hall exist in query parameters, set initial hall
        if (this.state.setInitialHall && this.props.locations.length > 1) {
            const selectedOption = this.props.locations.find(
                o => o.value === this.state.setInitialHall,
            );
            if (selectedOption) {
                this.props.applyFilter('location', selectedOption);
            }
            this.setState({ setInitialHall: '' });
        }
    }

    getDefaultCustomStyles = () => ({
        control: styles => ({
            ...styles,
            border: '0 none',
            marginLeft: 40,
            marginTop: 1,
        }),
    });

    getNextPage = () => {
        const nextPageNumber = this.state.pageNumber + 1;
        this.setState({ pageNumber: nextPageNumber }, () => {
            const newFilter = {
                ...this.props.filters,
                upcomingShowsPageNumber: this.state.pageNumber,
            };
            this.props.applyFilter(
                'upcomingShowsPageNumber',
                this.state.pageNumber,
            );
            this.props.getShows(newFilter);
        });
    };

    renderShows() {
        if (this.props.initialLoading) {
            return <Loading />;
        }
        let renderedShows = (
            <h2>{gettext('No results matching selected filters.')}</h2>
        );

        if (this.props.shows.length) {
            renderedShows = this.props.shows.map((show, i, arr) => {
                const key = `${show.id}-${show.staging.id}`;
                // We need to render separator after every 3 elements ( i % 3 ==== 0 )
                if (i !== 0 && i % 3 === 0 && i !== arr.length) {
                    return (
                        <>
                            <SingleShow show={show} key={key} />
                        </>
                    );
                }
                return <SingleShow show={show} key={key} />;
            });
        }

        return (
            <>
                <div className="__shows">{renderedShows}</div>
                {this.renderUpcomingShowsNextButton()}
            </>
        );
    }

    renderUpcomingShowsNextButton = () => {
        if (this.props.nextLoading) {
            return <Loading />;
        }
        // Display the next page button only if the upcoming shows option is selected,
        // if the next page is present
        const showUpcomingShowsNextButton =
            this.props.getFilterValue('period').value === 1 &&
            this.props.nextPage;
        if (showUpcomingShowsNextButton) {
            return (
                <div className="__next-page-div">
                    <button
                        className="btn btn-primary "
                        type="button"
                        onClick={() => {
                            this.getNextPage();
                        }}
                    >
                        {gettext('Next page')}
                    </button>
                </div>
            );
        }
        return null;
    };

    renderStagingSelector() {
        return (
            <Select
                value={this.props.getFilterValue('staging')}
                onChange={option => this.onFilterChange('staging', option)}
                components={{ DropdownIndicator }}
                options={this.props.stagings}
                className="react-select menu-outer-top"
                classNamePrefix="react-select"
                styles={this.getDefaultCustomStyles()}
            />
        );
    }

    renderPeriodSelector() {
        return (
            <Select
                value={this.props.getFilterValue('period')}
                onChange={option => this.onFilterChange('period', option)}
                components={{ DropdownIndicator }}
                options={this.props.periods}
                className="react-select"
                classNamePrefix="react-select"
                styles={this.getDefaultCustomStyles()}
            />
        );
    }

    renderLocationSelector() {
        return (
            <Select
                value={this.props.getFilterValue('location')}
                onChange={option => this.onFilterChange('location', option)}
                components={{ DropdownIndicator }}
                options={this.props.locations}
                className="react-select"
                classNamePrefix="react-select"
                styles={this.getDefaultCustomStyles()}
            />
        );
    }

    render() {
        return (
            <>
                <div className="__filters">
                    <div className="__filter __stagings">
                        {this.renderStagingSelector()}
                    </div>
                    <div className="__filter __dates">
                        {this.renderPeriodSelector()}
                    </div>
                    <div className="__filter __scenes">
                        {this.renderLocationSelector()}
                    </div>
                </div>
                {this.renderShows()}
            </>
        );
    }
}

const mapStateToProps = state => ({
    shows: selectors.shows(state),
    filters: selectors.filters(state),
    stagings: selectors.stagings_options(state),
    periods: selectors.period_options(state),
    locations: selectors.location_options(state),
    activeStagingSelection: selectors.staging(state),
    getFilterValue: filter => selectors.getSelectedFilter(state, filter),
    initialLoading: state.shows.initialLoading,
    nextLoading: state.shows.nextLoading,
    nextPage: selectors.nextPage(state),
});

const mapDispatchToProps = dispatch => ({
    getShows: (filters, reset) => dispatch(getShows(filters, reset)),
    getFilterOptions: filters => dispatch(getFilterOptions(filters)),
    applyFilter: (key, value) => {
        dispatch(setFilter(key, value));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomepageWrapper);
